/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .testimonial_card {
    margin: 2rem;
  }
  .testimonial_card .card-header {
    background-color: var(--maroon-dark);
    color: white;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .testimonial_row {
    display: flex;
    flex-direction: column;
  }
  .testimonial_card {
    margin: 4rem auto;
    width: 60%;
    padding: 10px;
  }
  .testimonial_card .card-header {
    background-color: var(--maroon-dark);
    color: white;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .testimonial_card {
    margin: 3rem;
    max-width: 40%;
  }
  .testimonial_card.left {
    float: left;
  }
  .testimonial_card.right {
    float: right;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
