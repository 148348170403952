/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home_overlay {
    z-index: 1;
  }
  .home_overlay_slides {
    padding-top: 250px;
  }
  .overlay_slide_bg_wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.3) 100%
      ),
      url("../StockPhotos/nose.jpg") !important;
    /* background-image: url("../StockPhotos/nose.jpg"); */
    background-repeat: no-repeat;
    background-position: 37% top;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 750px;
  }
  .home_overlay_container {
    min-height: 350px !important;
  }
  .overlay_container_inner {
    background: transparent;
    border: none;
    text-align: center;
    right: 20%;
    font-family: "Satisfy", cursive;
    color: #343a40 !important;
    margin-top: 5rem;
  }
  .home_overlay_button {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
  }
  .clear:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .home_overlay {
    z-index: 1;
  }
  .home_overlay_slides {
    padding-top: 150px;
  }
  .overlay_slide_bg_wrap {
    height: 700px;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.3) 100%
      ),
      url("../StockPhotos/nose.jpg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 750px;
  }
  .home_overlay_container {
    min-height: 300px !important;
  }
  .overlay_container_inner {
    background: transparent;
    border: none;
    text-align: center;
    right: 20%;
    font-family: "Satisfy", cursive;
    color: #343a40 !important;
    margin-top: 7rem;
  }
  .home_overlay_button {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
  }
  .clear:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .home_overlay_slides {
    padding-top: 50px;
  }
  .home_overlay_container {
    height: initial !important;
    min-height: initial !important;
  }
  .overlay_container_inner {
    right: 0%;
    font-family: "Satisfy", cursive;
    color: black !important;
    align-items: center;
    margin-top: 18.5rem;
  }
  .overlay_container_inner > .card-body > .card-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1rem -3rem;
    padding: 1rem 0;
    justify-content: center;
  }
  .overlay_container_inner > .card-body > .card-body > h2 {
    margin: 0.5rem;
  }
  .home_overlay_button {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .home_overlay_slides {
    padding-top: 10px;
  }
  .overlay_slide_bg_wrap {
    height: 1000px;
  }
  .overlay_slide_bg {
    background-position: center 15%;
  }
  .overlay_container_inner {
    margin-top: 345px;
  }
}
