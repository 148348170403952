@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400&family=Satisfy&display=swap");

:root {
  margin: 0;
  padding: 0;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  line-height: 1.5;
  --navy-primary: #207780;
  --navy-light: #57a6af;
  --navy-dark: #004b54;
  --maroon-primary: #af3d2d;
  --maroon-light: #e66d57;
  --maroon-dark: #790404;
  --corn-primary: #f3b406;
  --corn-light: #ffe64d;
  --corn-dark: #bb8500;
  background-color: var(--nav-dark);
}

.btn {
  background-color: var(--corn-primary);
  border-color: var(--corn-light);
  color: black;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: var(--navy-dark);
  border-color: var(--navy-primary);
  /*
  color: black;
  background-color: var(--bs-warning);
  border-color: var(--corn-light);  */
}

.dropdown-menu-dark .dropdown-item:active {
  background-color: var(--maroon-primary) !important;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 374px) {
  .row {
    margin: 0 auto;
  }
  .small_container {
    margin-left: -3.75vw !important;
  }
}

/* Min width means ABOVE
Max width means BELOW
*/
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 474px) and (min-width: 375px) {
  .row {
    margin: 0 auto;
  }
  .small_container {
    margin-left: -3.25vw !important;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (min-width: 475px) {
  .no_show_elm {
    position: relative;
    width: 0;
    height: 0%;
  }
  .row {
    margin: 0 auto;
  }
  .small_container {
    margin-left: -2.75vw;
    border: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .no_show_elm {
    position: relative;
    width: 0;
    height: 0%;
  }
  .row {
    margin: 0 auto;
  }
  .small_container {
    max-width: 100vw;
  }
  .content-page > .main_container {
    padding: 0%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
