/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home_about_container {
    padding: 4% 0;
    width: 100vw;
    background-color: white;
  }
  .about_container_inner {
    border: none;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #343a40 !important;
    width: inherit;
  }
  .about_pre_title {
    font-size: 26px;
  }
  .about_main_title {
    font-size: 32px;
    padding: 2% 0;
  }
  .about_body {
    padding: 1rem;
  }
  .about_body.about_questions > * {
    padding: 3% 0;
  }
  .about_image_rounded {
    border-radius: calc(0.25rem - 1px);
    width: 100%;
    object-fit: cover;
  }
  .about_col > .figure-caption {
    margin-top: 0.5rem;
    font-family: "Satisfy", cursive;
  }
  .dropdown-divider {
    border-top: 0.25rem solid rgba(0, 0, 0, 0.35);
    width: 95%;
    margin: 1rem auto;
    position: relative;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .home_about_container {
    padding: 4% 0;
    width: 100vw;
    background-color: white;
  }
  .about_container_inner {
    border: none;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #343a40 !important;
    width: inherit;
  }
  .about_pre_title {
    font-size: 32px;
  }
  .about_main_title {
    font-size: 46px;
    padding: 2% 0;
  }
  .about_body {
    padding: 1rem;
    margin: 0 auto;
    width: 90%;
  }
  .about_image_rounded {
    border-radius: calc(0.25rem - 1px);
    height: 27vw;
    object-fit: cover;
  }
  .about_col > .figure-caption {
    margin-top: 0.5rem;
    font-family: "Satisfy", cursive;
  }
  .about_image_rounded > .group_down {
    width: 125%;
  }
  .dropdown-divider {
    border-top: 0.25rem solid rgba(0, 0, 0, 0.35);
    width: 80%;
    margin: 4rem auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .about_body {
    width: 80%;
    margin: 1rem auto;
  }
}
