.toast {
  position: fixed;
  z-index: 10002;
  width: 60vw;
  min-height: 4rem;
  left: 35vw;
  top: 2rem;
  border-radius: 10px;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  visibility: hidden;

  transition: opacity 1000ms, background-color 1000ms, visibility 1000ms;
}

.toast div {
  font-size: 1.25rem;
  text-align: center;
}

.toast-show {
  opacity: 1;
  visibility: visible;
  background-color: #ffffff; /* default color */
  transition: opacity 1000ms, background-color, 1000ms, visibility 0ms;
}

/* make custom colors for other error codes here */
.toast-400 {
  background-color: #f35752;
}

.toast-200 {
  background-color: #5dd45f;
}

.toast-1 {
  background-color: #72afd3;
}

.toast-2 {
  background-color: #d18451;
}

.toast-413 {
  background-color: brown;
}
