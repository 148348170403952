/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home_services_offered_container {
    padding: 4% 0 0 0;
    width: 100vw;
    background-color: white;
  }
  .services_offered_title {
    font-size: 30px;
    padding: 2% 0;
    text-align: center;
    text-transform: uppercase;
  }
  .services_offered.card_inner {
    border: none;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #343a40 !important;
    width: inherit;
    margin-bottom: 1.5rem;
  }
  .services_offered.card_header {
    font-size: 32px;
    padding: 8% 0 10% 0;
    background-color: var(--maroon-primary);
    color: white;
  }
  .services_offered.card_body {
    padding: 1rem;
  }
  .services_offered.card_button {
    box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);
    margin-top: -15% !important;
    margin-bottom: -7% !important;
    display: inline-block;
  }
  .services_offered_row {
    margin: 0 auto;
  }
  .services_offered.programs_button {
    box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);
    font-size: 18px;
    text-transform: uppercase;
  }
  .services_offered.second_row {
    position: relative;
    margin: 1.5rem auto 0 auto;
    padding: 4rem 0.5rem 0 0.5rem;
  }
  .services_offered.second_row_divider {
    background-image: url("../BgOptions/watercolor_gold.jpg");
    background-position: center;
    background-size: cover;
    position: absolute;
    height: 100%;
    margin-top: -4rem;
  }
  .services_offered.card_inner.transparent {
    background: none;
    width: 100%;
    height: 80%;
    margin-bottom: 2.5rem;
  }
  .services_offered.card_inner.transparent > .rounded-circle {
    width: 100%;
    height: auto;
  }
  .services_offered.card_inner.transparent > .figure-caption {
    font-family: "Satisfy", cursive;
  }
  .services_offered.card_inner.transparent > .card-body {
    background-color: white;
    margin-bottom: 1rem;
    padding: 0 0 !important;
  }
  .services_offered.second_row_card_button {
    margin: 1rem;
  }
  .services_offered.card_inner.why_us {
    background-color: transparent;
    border: 1px solid white;
    width: 90%;
    margin: 1.5rem auto;
  }
  .services_offered_third_row > .services_offered_title_col {
    z-index: 1;
  }

  .services_offered_third_row {
    position: relative;
    padding-bottom: 10rem;
  }
  .services_offered_third_row > .third_row_divider {
    background-image: url("../BgOptions/transparent_hex.png");
    background-size: cover;
    position: absolute;
    height: 100%;
    z-index: 0;
  }
  .services_offered.card_inner.why_us > .card-body > .card_header {
    background-color: transparent !important;
    color: black;
    border: none;
  }
  .dropdown-divider {
    border-top: 0.25rem solid rgba(0, 0, 0, 0.35);
    width: 80%;
    margin: 4rem auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .services_offered_title {
    font-size: 60px;
    padding: 2% 0;
    text-align: center;
    text-transform: uppercase;
  }
  .services_offered.card_inner {
    border: none;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #343a40 !important;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .services_offered.card_header {
    font-size: 36px;
    padding: 3% 0 5% 0;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--maroon-primary);
    color: white;
  }
  .services_offered.card_body {
    padding: 1rem;
  }
  .services_offered.card_button {
    box-shadow: 0px 18px 24px -6px rgb(0 0 0 / 30%);
    margin-top: -12% !important;
    margin-bottom: -7% !important;
    display: inline-block;
  }
  .services_offered_row > .col-md-5 {
    margin: 0 auto;
  }
  .services_offered.programs_button {
    box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);
    font-size: 18px;
    text-transform: uppercase;
  }
  .services_offered.second_row {
    position: relative;
    background-image: linear-gradient(
      180deg,
      #ffffff 0%,
      #a95c68 0%
    ) !important;
    width: 100vw;
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);
    margin: 4rem auto;
    padding: 6rem 2rem;
  }

  .services_offered.second_row_divider {
    background-image: url("../BgOptions/watercolor_gold.jpg");
    background-position: center;
    background-size: cover;
    position: absolute;
    height: 100%;
    margin-top: -6rem;
  }
  .services_offered.card_inner.transparent {
    background: none;
    width: 100%;
    height: 80%;
    margin-bottom: 2.5rem;
  }
  .services_offered.card_inner.transparent > .rounded-circle {
    margin: 0 auto;
    width: 80%;
    height: auto;
  }
  .services_offered.card_inner.transparent > .figure-caption {
    font-size: 22px;
    font-family: "Satisfy", cursive;
  }
  .services_offered.card_inner.transparent > .card-body {
    background-color: white;
    margin-bottom: 1rem;
    padding: 0 0 !important;
  }
  .services_offered.second_row_card_button {
    margin: 1rem;
  }
  .services_offered_third_row {
    position: relative;
    width: 100vw;
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);
    padding-bottom: 10rem;
  }
  .services_offered_third_row > .third_row_divider {
    /* background-image: linear-gradient(
      90deg,
      rgba(165,90,101,0.5) 0%,
      rgba(165,90,101,0.5) 100%
      ),  url("../BgOptions/transparent_hex.png") !important; */
    background-image: url("../BgOptions/transparent_hex.png");
    position: absolute;
    height: 100%;
    margin-top: -4rem;
    z-index: -1;
  }
  .services_offered.card_inner.why_us {
    background-color: transparent;
    border: 1px solid white;
  }
  .services_offered.card_inner.why_us > .card-body > .card_header {
    background-color: transparent !important;
    color: black;
    border: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .services_offered.card_header {
    padding: 0.5rem 1rem;
    min-height: 135px;
  }
  .services_offered.second_row {
    padding: 6rem 0rem;
  }
  .services_offered.second_row_divider {
    background-position: top;
  }
  .services_offered.card_inner.transparent {
    background: none;
    width: 100%;
    height: 100%;
    margin-bottom: 0.5rem;
  }
  .services_offered.card_inner.transparent > .rounded-circle {
    width: auto;
    max-height: 247px;
  }
  .services_offered.card_inner.transparent > .figure-caption {
    font-size: 18px;
    min-height: 56px;
  }
  .services_offered.card_inner.transparent > .card-body {
    display: flex;
    flex-direction: column;
  }
  .services_offered.card_inner.why_us {
    height: 100%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
