/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .navbar_header {
    background-color: var(--navy-dark);
    z-index: 10001;
  }
  .navbar_header > .nav-link {
    padding: 0.5rem 0.4rem;
  }
  .icon_color {
    color: white;
  }
  .modal-backdrop {
    z-index: 10000;
  }
  .fade.modal.show {
    z-index: 10001;
  }
  .modal-content {
    width: 90%;
  }
  .modal-body > .container > .row > div {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 991px) {
  .navbar_header {
    background-color: var(--navy-dark);
    z-index: 10001;
    position: relative !important;
    justify-content: center;
  }
  .navbar_header > .nav-link {
    padding: 0.5rem 2.5rem;
  }
  .icon_color {
    color: white;
  }
  .modal-backdrop {
    z-index: 10000;
  }
  .fade.modal.show {
    z-index: 10001;
  }
  .modal-body > .container > .row > div {
    margin-bottom: 1rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* Footer and Header Icons */
  .navbar_header > .nav-link {
    padding: 0.5rem 2.5rem;
  }

  /* Header Icons */
  .navbar_header.sticky-top {
    z-index: 10001;
    position: relative !important;
    justify-content: center;
  }
  .sticky-top > .nav-link > .icon_color {
    color: var(--navy-dark);
  }

  /* Footer Icons */
  .navbar_header.fixed-bottom {
    background-color: var(--navy-dark) !important;
    z-index: 10001;
    position: relative !important;
    justify-content: center;
  }
  .fixed-bottom > .nav-link > .icon_color {
    color: var(--corn-primary);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
