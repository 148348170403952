/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .page_overlay {
    z-index: 1;
    position: relative;
    top: -12rem;
    margin: 0 -4%;
  }
  .page_overlay_slides {
    position: relative;
    height: 600px;
  }
  .page_overlay_slide_bg_wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .page_overlay_slide_bg {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100vw;
    height: 600px;
  }

  /* TrainingAll */
  .trainingAll.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.99) 10%,
        rgba(255, 255, 255, 0) 50%
      ),
      url("../StockPhotos/training_3.jpeg");
    background-size: 175%;
    background-position: 30% 40%;
  }
  /* Private Lessons */
  .privateLessons.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.99) 10%,
        rgba(255, 255, 255, 0) 50%
      ),
      url("../StockPhotos/in_home_training.jpg");
    background-position: 12% 8rem;
  }
  /* Board And Train */
  .boardAndTrain.page_overlay_slide_bg {
    background-image: url("../StockPhotos/walking_in_field.jpg");
    background-position: 50% 0%;
  }
  /* Buy A Trained Puppy */
  .buyATrainedPuppy.page_overlay_slide_bg {
    top: 12rem;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.99) 1%,
        rgba(255, 255, 255, 0) 20%
      ),
      url("../StockPhotos/pup_for_sale.jpg");
    background-position: 60% 1rem;
  }
  /* Day Training */
  .dayTraining.page_overlay_slide_bg {
    top: 12rem;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.99) 1%,
        rgba(255, 255, 255, 0) 20%
      ),
      url("../StockPhotos/daytraining.jpg");
    background-position: 60% -5rem;
  }
  /* B&T Adult */
  .btAdult.page_overlay_slide_bg {
    top: 12rem;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../StockPhotos/btadult.jpg");
    background-position: 55% -4rem;
  }
  /* B&T Puppy */
  .btPuppy.page_overlay_slide_bg {
    top: 12rem;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../StockPhotos/btpuppy.jpg");
    background-position: 60% -5rem;
  }
  /* Boarding */
  .boarding.page_overlay_slide_bg {
    top: 10rem;
    background-image: url("../StockPhotos/boarding.jpeg");
    background-position: 50% 0%;
  }
  /* Meet The Team */
  .teamAll.page_overlay_slide_bg {
    top: 12rem;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../StockPhotos/rora_justine_zey.jpg");
    background-position: 50% -2rem;
    background-size: 220%;
  }
  /* Meet Justine */
  .aboutJustine.page_overlay_slide_bg {
    top: 12rem;
    background-image: url("../StockPhotos/teamOverlayJustine.jpg");
    background-position: 50% 80%;
    background-size: 220%;
  }
  /* Meet Kyra */
  .aboutKyra.page_overlay_slide_bg {
    top: 12rem;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../StockPhotos/teamOverlayKyra.jpg");
    background-position: 30% -2rem;
    background-size: 220%;
  }
  /* Meet Munch */
  .aboutMunch.page_overlay_slide_bg {
    top: 12rem;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../StockPhotos/teamOverlayMunch.png");
    background-position: 10% 0rem;
    background-size: 120%;
  }
  /* Meet Zeta */
  .aboutZeta.page_overlay_slide_bg {
    top: 12rem;
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0.6) 70%
      ),
      url("../StockPhotos/teamOverlayZeta.jpg");
    background-position: 40% -2rem;
    filter: brightness(140%);
  }
  /* Meet Aurora */
  .aboutAurora.page_overlay_slide_bg {
    top: 12rem;
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 0.8) 70%
      ),
      url("../StockPhotos/teamOverlayAurora.jpg");
    background-position: 60% 0rem;
  }
  /* Meet Seeley */
  .aboutSeeley.page_overlay_slide_bg {
    top: 12rem;
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 0.8) 70%
      ),
      url("../StockPhotos/todosteamSeeley.jpeg");
    background-position: 60% 0rem;
  }
  /* Contact Page */
  .contactUs.page_overlay_slide_bg {
    top: 12rem;
    background-image: url("../StockPhotos/contactUs.jpg");
    background-position: 50% 95%;
  }

  .page_overlay_container {
    height: 600px;
    min-height: 600px !important;
    position: relative;
    top: 0;
    display: flex;
    align-items: flex-end;
    text-align: left;
  }
  .page_overlay_container_inner {
    background: transparent;
    border: none;
    width: 100%;
    padding: 15px 0;
  }
  .page_overlay_container_inner > .card-body > .card-title > h1 {
    font-size: 32px !important;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
  }
  .page_overlay_container_inner > .card-body > .figure-caption {
    font-family: "Satisfy", cursive;
    color: white;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: -1.5rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .page_overlay {
    z-index: 1;
    position: relative;
    top: -10rem;
  }
  .page_overlay_slides {
    position: relative;
    height: 600px;
  }
  .page_overlay_slide_bg_wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .page_overlay_slide_bg {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100vw;
    height: 600px;
    display: block;
  }

  /* TrainingAll */
  .trainingAll.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      url("../StockPhotos/training_3.jpeg");
    background-position: 10% 40%;
  }
  /* Private Lessons */
  .privateLessons.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      url("../StockPhotos/in_home_training.jpg");
    background-position: 0% 5rem;
  }
  /* Board And Train */
  .boardAndTrain.page_overlay_slide_bg {
    background-image: url("../StockPhotos/walking_in_field.jpg");
    background-size: initial;
    background-position: 50% 70%;
  }
  /* Buy A Trained Puppy */
  .buyATrainedPuppy.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      url("../StockPhotos/pup_for_sale.jpg");
    background-position: 0% 10rem;
  }
  /* Day Training */
  .dayTraining.page_overlay_slide_bg {
    background-image: url("../StockPhotos/daytraining.jpg");
    background-position: 0% 20%;
  }
  /* B&T Adult */
  .btAdult.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../StockPhotos/btadult.jpg");
    background-position: 50% 5rem;
  }
  /* B&T Puppy */
  .btPuppy.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../StockPhotos/btpuppy.jpg");
    background-position: 0% 4rem;
  }
  /* Boarding */
  .boarding.page_overlay_slide_bg {
    background-image: url("../StockPhotos/boarding.jpeg");
    background-position: 50% 5rem;
  }
  /* Meet The Team */
  .teamAll.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../StockPhotos/rora_justine_zey.jpg");
    background-position: 50% 10rem;
  }
  /* Meet Justine */
  .aboutJustine.page_overlay_slide_bg {
    background-image: url("../StockPhotos/teamOverlayJustine.jpg");
    background-position: 0% 40%;
  }
  /* Meet Kyra */
  .aboutKyra.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../StockPhotos/teamOverlayKyra.jpg");
    background-position: 0% 10rem;
  }
  /* Meet Munch */
  .aboutMunch.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../StockPhotos/teamOverlayMunch.png");
    background-position: center 10rem;
    background-size: 60%;
    background-color: var(--corn-dark);
  }
  /* Meet Zeta */
  .aboutZeta.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 90%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url("../StockPhotos/teamOverlayZeta.jpg");
    background-position: 0% 8rem;
    filter: brightness(140%);
  }
  /* Meet Aurora */
  .aboutAurora.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 90%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url("../StockPhotos/teamOverlayAurora.jpg");
    background-position: 70% 8rem;
  }
  /* Meet Seeley */
  .aboutSeeley.page_overlay_slide_bg {
    background-image: url("../StockPhotos/todosteamSeeley.jpeg");
    background-position: 60% 6rem;
  }
  /* Contact Page */
  .contactUs.page_overlay_slide_bg {
    background-image: url("../StockPhotos/contactUs.jpg");
    background-position: 50% 25%;
  }

  .page_overlay_container {
    height: 600px;
    min-height: 600px !important;
    position: relative;
    top: 0;
    display: flex;
    align-items: flex-end;
    text-align: left;
    max-width: 100vw;
  }
  .page_overlay_container_inner {
    background: transparent;
    border: none;
    width: 100vw;
    padding: 15px 0;
    margin: 0 32px;
  }
  .page_overlay_container_inner > .card-body > .card-title > h1 {
    font-size: 50px !important;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
  }
  .page_overlay_container_inner > .card-body > .figure-caption {
    font-family: "Satisfy", cursive;
    color: white;
    text-align: right;
    margin-top: 1rem;
    margin-bottom: -1.5rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .page_overlay {
    top: -15rem;
  }
  /* TrainingAll */
  .trainingAll.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.99) 1%,
        rgba(255, 255, 255, 0) 60%
      ),
      url("../StockPhotos/training_3.jpeg");
    background-position: 0% 45%;
  }
  /* Private Lessons */
  .privateLessons.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      url("../StockPhotos/in_home_training.jpg");
    background-position: 0% 10%;
  }
  /* Board And Train */
  .boardAndTrain.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../StockPhotos/walking_in_field.jpg");
    background-position: 50% 70%;
  }
  /* Buy A Trained Puppy */
  .buyATrainedPuppy.page_overlay_slide_bg {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      url("../StockPhotos/pup_for_sale.jpg");
    background-position: 0% 5rem;
  }
  /* Day Training */
  .dayTraining.page_overlay_slide_bg {
    background-position: 0% 40%;
  }
  /* B&T Adult */
  /* .btAdult.page_overlay_slide_bg {
  } */
  /* B&T Puppy */
  .btPuppy.page_overlay_slide_bg {
    background-position: 0% 4rem;
  }
  /* Boarding */
  /* .boarding.page_overlay_slide_bg {
    background-image: url("../StockPhotos/boarding.jpeg");
    background-position: 50% 0%;
  } */
  /* Meet The Team */
  .teamAll.page_overlay_slide_bg {
    background-position: 50% 2rem;
  }
  /* Meet Justine */
  .aboutJustine.page_overlay_slide_bg {
    background-position: 0% 50%;
  }
  /* Meet Kyra */
  .aboutKyra.page_overlay_slide_bg {
    background-position: 30% 2rem;
  }
  /* Meet Munch */
  .aboutMunch.page_overlay_slide_bg {
    background-position: 50% 5rem;
  }
  /* Meet Zeta */
  .aboutZeta.page_overlay_slide_bg {
    background-position: 0% 50%;
  }
  /* Meet Aurora */
  .aboutAurora.page_overlay_slide_bg {
    background-position: 0% 40%;
  }

  .page_overlay_container {
    min-height: initial !important;
  }
  .page_overlay_container_inner > .card-body > .figure-caption {
    font-size: 1em;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* TrainingAll */
  .trainingAll.page_overlay_slide_bg {
    background-position: 0% 51%;
  }
  /* Private Lessons */
  .privateLessons.page_overlay_slide_bg {
    background-size: 110%;
    background-position: 10% 30%;
  }
  /* Board And Train */
  .boardAndTrain.page_overlay_slide_bg {
    /* background-size: ; */
    background-position: 50% 70%;
  }
  /* Buy A Trained Puppy */
  .buyATrainedPuppy.page_overlay_slide_bg {
    background-position: 0% 0%;
  }
  /* Day Training */
  .dayTraining.page_overlay_slide_bg {
    background-position: 0% 50%;
  }
  /* B&T Adult */
  .btAdult.page_overlay_slide_bg {
    background-position: 50% 60%;
  }
  /* B&T Puppy */
  .btPuppy.page_overlay_slide_bg {
    background-position: 0% 20%;
  }
  /* Meet The Team */
  .teamAll.page_overlay_slide_bg {
    background-position: 50% 30%;
  }
  /* Meet Justine */
  .aboutJustine.page_overlay_slide_bg {
    background-position: 0% 55%;
  }
  /* Meet Kyra */
  .aboutKyra.page_overlay_slide_bg {
    background-position: 90% 30%;
  }
  /* Meet Munch */
  .aboutMunch.page_overlay_slide_bg {
    background-position: center 5rem;
    background-size: 40%;
  }
  /* Meet Aurora */
  .aboutAurora.page_overlay_slide_bg {
    background-position: 0% 0%;
  }
  /* Contact Page */
  .contactUs.page_overlay_slide_bg {
    background-position: 50% 40%;
  }

  .page_info_container {
    background-color: white;
  }
  .page_overlay_container_inner > .card-body > .figure-caption {
    font-size: 1.25em;
  }
}
