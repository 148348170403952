/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .logo_container {
    height: auto;
    padding: 0;
    position: absolute;
    top: 17rem;
    margin: 0 auto;
    z-index: 9999;
    width: 80% !important;
  }
  span.logo-lg {
    display: none;
  }
  span.logo-sm {
    height: inherit;
    display: inline-block;
    width: inherit;
  }
  .logo {
    max-height: 100% !important;
    max-width: 300px !important;
    margin-top: 0 !important;
  }
  .logo-sm > img {
    width: inherit;
  }
  .navbar_header .logo_container {
    display: none;
  }
  .navbar_header .logo_nav {
    display: none;
  }
  .navbar_header .logo {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .logo_container {
    height: auto;
    padding: 0;
    position: absolute;
    top: 10%;
    margin: 1rem auto;
    z-index: 9999;
    width: 90% !important;
  }
  span.logo-lg {
    height: inherit;
    display: inline-block;
    width: inherit;
  }
  span.logo-sm {
    display: none;
  }
  .logo {
    max-height: 100% !important;
    max-width: 500px !important;
    margin-top: 0 !important;
    margin-left: 10%;
    width: 100%;
  }
  .navbar_header .logo_container {
    position: relative;
    z-index: 10100;
  }
  .navbar_header .logo_nav {
    padding: 0 !important;
  }
  .navbar_header .logo {
    max-width: 200px !important;
    margin-left: 2%;
    filter: drop-shadow(2px 2px 4px black);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .logo_container {
    max-width: 100vw;
    width: 100% !important;
    left: -7%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .logo {
    width: auto;
    max-height: 100%;
    height: inherit;
  }
  span.logo-lg {
    display: inline-block;
    height: inherit;
  }
  span.logo-sm {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
