/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .navigation_brand,
  .navigation_component {
    display: none;
  }
  .navigation_navbar {
    background-color: transparent !important;
    display: flex;
    justify-content: flex-end;
    z-index: 10000;
  }
  .navigation_toggle {
    margin-right: 1rem;
    margin-bottom: 0.25rem;
    opacity: 1;
    background-color: var(--navy-primary);
    color: white !important;
    border-color: white !important;
  }
  .navbar_collapse {
    margin-right: 1rem;
    background-color: var(--maroon-dark);
    padding: 5%;
    font-size: 125%;
    color: white;
    border-radius: 0.5rem;
    position: absolute;
    top: 100%;
    width: 65vw;
  }
  .navbar_collapse .nav-link {
    color: white !important;
    padding-left: 5%;
  }
  .fixed-bottom {
    position: relative !important;
  }
  .navbar_collapse .nav-item .dropdown-menu {
    background-color: var(--maroon-dark);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 991px) {
  .navigation_brand,
  .navigation_component {
    display: none;
  }
  .navigation_navbar {
    background-color: transparent !important;
    display: flex;
    justify-content: flex-end;
    z-index: 10000;
  }
  .navigation_toggle {
    margin-right: 1rem;
    margin-bottom: 0.25rem;
    opacity: 1;
    background-color: var(--navy-primary);
    color: white !important;
    border-color: white !important;
  }
  .navbar_collapse {
    margin-right: 1rem;
    background-color: var(--maroon-dark);
    padding: 5%;
    font-size: 125%;
    color: white;
    border-radius: 0.5rem;
    position: absolute;
    top: 100%;
    width: 40vw;
  }
  .navbar_collapse .nav-link {
    color: white !important;
    padding-left: 5%;
  }
  .fixed-bottom {
    position: relative !important;
  }
  .navbar_collapse .nav-item .dropdown-menu {
    background-color: var(--maroon-dark);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* Hamburger menu in IconHeader */
  .sticky-top > .navigation_navbar {
    background-color: var(--navy-primary) !important;
    z-index: 10000;
    padding: 0 0;
    margin: 0.5rem 2.5rem;
    border-radius: 0.5rem;
  }
  .sticky-top > .navigation_navbar > .navbar_collapse {
    background-color: var(--maroon-dark);
    padding: 5%;
    font-size: 125%;
    color: white;
    border-radius: 0.5rem;
    position: absolute;
    top: 100%;
    width: 30vw;
    right: 0%;
  }
  .sticky-top > .navigation_navbar > .navbar_collapse .nav-link {
    color: white !important;
    padding-left: 5%;
  }
  .navbar_collapse .nav-item .dropdown-menu {
    background-color: var(--maroon-dark);
  }
  /* Navbar below nose */
  .navigation_brand {
    display: none;
  }
  .navigation_component {
    display: initial;
  }
  .navigation_component > .navigation_navbar {
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    z-index: 10000;
    padding: 2% 5%;
    top: 13rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.05rem;
  }
  .navigation_component > .navigation_navbar a {
    color: white;
  }
  .navigation_component > .navigation_navbar > .navbar_collapse .nav-link {
    color: var(--navy-dark);
  }
  .navigation_component
    > .navigation_navbar
    > .navbar_collapse
    .nav-item
    .dropdown-menu {
    background-color: var(--maroon-dark);
  }
  .navigation_component
    > .navigation_navbar
    > .navbar_collapse
    .dropdown-menu-dark {
    background-color: white;
    color: #343a40;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
