/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .contact_section {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.58) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      url("../StockPhotos/store.jpeg") !important;
    background-position-x: 60%;
    background-position-y: 75%;
    width: 100vw;
    padding: 30% 0;
    color: white;
  }
  .contact_us_form {
    width: 90vw;
    margin: 0 auto;
  }
  .contact_section_button {
    width: 80vw;
    margin-left: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }
  .contact_us_form > .figure-caption {
    margin-top: 3rem;
    font-family: "Satisfy", cursive;
    color: white;
    padding: 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .contact_section {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.58) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      url("../StockPhotos/store.jpeg") !important;
    background-position-x: 60%;
    background-position-y: 75%;
    width: 100vw;
    padding: 30% 0;
    /* margin: -0.75rem; */
    color: white;
    margin-top: -4rem;
  }
  .contact_section_button {
    font-size: 22px;
    width: 45%;
    margin-left: 28%;
    margin-bottom: 2rem;
    padding: 0.5rem;
  }
  .contact_us_form > .figure-caption {
    margin-top: 3rem;
    text-align: center;
    font-size: 20px;
    font-family: "Satisfy", cursive;
    color: white;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .contact_section {
    background-position-x: 0%;
    background-position-y: 100%;
    padding: 30% 0 20% 0;
    background-size: cover;
  }
  .contact_us_form {
    margin: 0 1rem;
  }
  .contact_us_form > .figure-caption {
    font-size: 18px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .contact_section {
    height: 900px;
    background-position-y: 60%;
    padding: 10% 0 10% 0;
    background-size: cover;
  }
  .contact_us_form {
    max-width: 600px;
    margin: 0 auto;
  }
  .contact_us_form > .figure-caption {
    font-size: 24px;
  }
}
